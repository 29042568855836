<template>
  <span>
    Расчётная цена <strong>{{price}}</strong> р.
  </span>
</template>
<script>
export default {
  props: {
    price: {
      required: true,
      type: Number
    },

  }
};
</script>
<style>

</style>